
export default {
    props: {
        background: {
            type: String,
            default: 'default'
        },
        header: {
            type: String,
            default: null
        },
        linkBlock: {
            type: Array,
            default: () => { return null; }
        }
    }
};
