import { render, staticRenderFns } from "./WorldMap.vue?vue&type=template&id=b3e3b4ec"
import script from "./WorldMap.vue?vue&type=script&lang=js"
export * from "./WorldMap.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WorldMap: require('/vercel/path0/components/WorldMap/WorldMap.vue').default,BaseIcon: require('/vercel/path0/components/BaseIcon/BaseIcon.vue').default,LinkBlock: require('/vercel/path0/components/LinkBlock/LinkBlock.vue').default,BaseSection: require('/vercel/path0/components/BaseSection/BaseSection.vue').default})
